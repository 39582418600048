<template>
  <div>
    <!--begin::Assessment-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ assessment_name }}</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <!-- <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToAssessmentList()">
                  Back
                </span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_assessment_form">
          <!--begin::Example-->
          <div
            class="example justify-content-center"
            v-if="pre_assessment_info"
          >
            <h3>Assessment Information</h3>
            <br/>
            <div v-html="pre_assessment_info" style="font-size: 16px;"></div>
            <v-row fluid class="card-footer pl-3 mt-3">
              <v-btn
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="startAssessment(0)"
              >
                Start Assessment
              </v-btn>
              <v-btn
                class="mr-4 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
                ref="kt_store_submit"
                @click="redirectToAssessmentList()"
              >
                Cancel
              </v-btn>
            </v-row>
          </div>
          <div class="example" v-else-if="!complete_assessment">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="12"> {{ order }}. {{ question }} </v-col>
              </v-row>
              <v-row no-gutters v-if="is_informational==0">
                <v-radio-group v-model="selected_option">
                  <v-col cols="12" sm="4">
                    <v-radio
                      class="color-purple"
                      :label="option1.option_value"
                      :value="option1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-radio
                      class="color-purple"
                      :label="option2.option_value"
                      :value="option2"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="is_NA==1">
                    <v-radio
                      class="color-purple"
                      :label="option3.option_value"
                      :value="option3"
                    ></v-radio>
                  </v-col>
                </v-radio-group>
              </v-row>
              <v-row no-gutters v-else>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="option_value"
                    label="Answer"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <h5 class="mt-3 mb-3">
                Completed ({{ order - 1 }}/{{ number_of_questions }})
              </h5>
              <b-progress :max="max_progress">
                <b-progress-bar
                  :value="current_progress"
                  :label="
                    `${((current_progress / max_progress) * 100).toFixed(2)}%`
                  "
                >
                </b-progress-bar>
              </b-progress>

              <v-row fluid class="card-footer pl-3 mt-3">
                <div class="col-md-4">
                  <v-btn
                    v-if="!view_submit"
                    color="success"
                    class="mr-4"
                    ref="kt_store_submit"
                    @click="validateAnswer"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    v-else-if="view_submit && !view_ok_next"
                    color="success"
                    class="mr-4"
                    ref="kt_store_submit"
                    @click="submitAssessment"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    v-else
                    color="success"
                    class="mr-4"
                    ref="kt_store_submit"
                    @click="postInfoViewed"
                  >
                    Ok!
                  </v-btn>
                </div>
                <div class="col-md-8 text-right">
                  <v-btn
                    v-if="!is_first_question"
                    color="warning"
                    dark
                    @click="saveAndExitAssessment()"
                  >
                    Save and Exit Assessment
                  </v-btn>
                  <v-btn
                    class="ml-5"
                    color="error"
                    @click="cancelAndExitAssessment()"
                    dark
                  >
                    Cancel and Exit Assessment
                  </v-btn>
                </div>
              </v-row>
            </v-form>
          </div>
          <div class="example justify-content-center" v-else>
            <h3 style="text-align: center;">Post Assessment Information</h3>
            Score Risk : <b>{{ score_interpretation_label }}</b> <br />
            {{ post_assessment_info }}<br />
            <b> Feedback of assessment </b><br />
            <ul>
              <template v-for="(feedback, index) in feedback_points">
                <span v-if="feedback" :key="index">
                  <li><div v-html="feedback"></div></li>
                </span>
                <br v-if="feedback" :key="index" />
              </template>
            </ul>
            <br />
            <!--b> Recommandations For you </b><br />
            <template v-for="(recommandation, index) in recommandations">
              <span v-if="recommandation" :key="index">
                <div v-html="recommandation"></div>
              </span>
            </template>
            <br /-->
            <v-row fluid class="card-footer pl-3 mt-3">
              <v-btn
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="redirectToAssessmentList()"
              >
                Ok
              </v-btn>
            </v-row>
          </div>
        </form>
      </div>
    </div>
    <!--end::Contacs-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import {
  STORE_ANSWER,
  STORE_ASSESSMENT_RESULT,
  CANCEL_ASSESSMENT_EXIT,
  SAVE_ASSESSMENT_EXIT
} from "@/core/services/store/assessment.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  name: "AssessmentFill",
  vuetify: new Vuetify(),
  data() {
    return {
      assessment_name: "",
      is_NA: 0,
      question: "",
      option1: "",
      option2: "",
      option3: "",
      option_value: "",
      section_id: "",
      valid: true,
      disable_btn: false,
      max_progress: 100,
      current_progress: 0,
      selected_option: "",
      order: 1,
      number_of_questions: 0,
      progress_interval: 0,
      assessment_score: 0,
      view_submit: false,
      view_ok_next: false,
      pre_assessment_info: "",
      post_assessment_info: "",
      score_interpretation_label: "",
      complete_assessment: false,
      is_first_question: false,
      recommandations: [],
      feedback_points: [],
      is_informational: 0
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  watch: {
    selected_option: function(newVal) {
      if(newVal && this.selected_option.next_question_id == 0){
        this.view_submit = true;
      }else{
        this.view_submit = false;
      }
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id && this.$route.params.question_id) {
      // context.startAssessment(this.$route.params.question_id);
      context.selected_option = {
        next_question_id: this.$route.params.question_id
      };
      context.getNextQuestion();
      context.is_first_question = true;
    }

    EventBus.$on("STORE_ASSESSMENT_SCORE", function(payLoad) {
      context.view_ok_next = true;
      // context.post_assessment_info = payLoad.result.post_assessment_info;
      context.post_assessment_info = payLoad.result.score_interpretation_text;
      context.feedback_points = payLoad.result.feedback_points;
      context.score_interpretation_label =
        payLoad.result.score_interpretation_label;
      context.recommandations = payLoad.result.recommandations;
      context.complete_assessment = true;
    });

    EventBus.$on("STORE_ASSESSMENT_ANSWER", function(payLoad) {
      if (payLoad) {
        // context.assessment_score += parseInt(
        //   context.selected_option.option_score
        // );
        context.is_first_question = false;
        context.getNextQuestion();
      } else {
        Swal.fire("Error", "Error in storing Assessment.", "error");
      }
    });

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Assessment" }]);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    saveAndExitAssessment() {
      let context = this;
      Swal.fire({
        title: "Do you want to save the assessment and exit?",
        showCancelButton: true,
        confirmButtonText: "Save and Exit"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store
            .dispatch(SAVE_ASSESSMENT_EXIT, {
              assessment_master_id: this.$route.params.id
            })
            .then(() => {
              Swal.fire("Saved!", "Assessment saved successfully", "success");
            })
            .then(() => {
              context.redirectToAssessmentList();
            });
        }
      });
    },
    cancelAndExitAssessment() {
      let context = this;
      Swal.fire({
        title: "Do you want to cancel the assessment and exit?",
        showCancelButton: true,
        confirmButtonText: "Cancel and Exit"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store
            .dispatch(CANCEL_ASSESSMENT_EXIT, {
              assessment_master_id: this.$route.params.id
            })
            .then(() => {
              Swal.fire(
                "Saved!",
                "Assessment cancelled successfully",
                "success"
              );
            })
            .then(() => {
              context.redirectToAssessmentList();
            });
        }
      });
    },
    startAssessment(order) {
      let context = this;
      axios({
        method: "get",
        url: "assessment-questionnaire",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: { order: order, assessment_master_id: this.$route.params.id }
      }).then(
        function(result) {
          if (result.data.assessment_name) {
            context.assessment_name = result.data.assessment_name;
          }
          if (result.data.assessment_question) {
            context.question_id = result.data.assessment_question.id;
            context.question = result.data.assessment_question.question_text;
            context.number_of_questions =
              result.data.assessment_question.number_of_questions;
            context.order = parseInt(result.data.assessment_question.order) + 1;
            context.is_NA = result.data.assessment_question.is_NA;
            context.option1 = result.data.assessment_question.options[0];
            context.option2 = result.data.assessment_question.options[1];

            if(result.data.assessment_question.is_NA == 1){
              context.option3 = result.data.assessment_question.options[2];
            }

            context.section_id = result.data.assessment_question.section_id;
            context.progress_interval = (
              100 / context.number_of_questions
            ).toFixed(2);
            context.current_progress = parseInt(
              context.progress_interval * context.order
            );
            context.pre_assessment_info = "";
          } else if (result.data.assessment_pre_info) {
            context.pre_assessment_info = result.data.assessment_pre_info;
          } else {
            // context.$router.go(-1); //go back to list
            Swal.fire(result.data.error, "Question not found.", "error");
          }
        },
        function() {
          context.redirectToAssessmentList(); //go back to list
          Swal.fire("Error", "Assessment not found!", "error");
        }
      );
    },
    validateAnswer() {
      let context = this;
      if(context.is_informational == 1){
        if (!context.option_value) {
          Swal.fire("Error", "Please add an answer.", "error");
        } else {
          context.storeAssessmentAnswer();
        }
      }else{
        if (!context.selected_option) {
          Swal.fire("Error", "Please select one option.", "error");
        } else {
          context.storeAssessmentAnswer();
        }
      }
    },
    submitAssessment() {
      let context = this;
      if(context.is_informational == 1){
          if (!context.option_value) {
            Swal.fire("Error", "Please add an answer.", "error");
          } else {
            context.storeAssessmentAnswer();
             var qryObj = {
              assessment_master_id: this.$route.params.id,
              section_id: context.section_id,
              attempt_id: 1
            };
            this.$store.dispatch(STORE_ASSESSMENT_RESULT, qryObj).then(() => {
              context.view_ok_next = true;
            });
          }
        }else{
          if (!context.selected_option) {
            Swal.fire("Error", "Please select one option.", "error");
          } else {
              context.storeAssessmentAnswer();
              var qryObj = {
                assessment_master_id: this.$route.params.id,
                section_id: context.section_id,
                attempt_id: 1
              };
              this.$store.dispatch(STORE_ASSESSMENT_RESULT, qryObj).then(() => {
                context.view_ok_next = true;
              });
          }
        }

    },
    postInfoViewed() {
      let context = this;
      context.redirectToAssessmentList();
    },
    storeAssessmentAnswer() {
      let context = this;
      if(context.is_informational == 1){
        var qryObj = {
          assessment_master_id: this.$route.params.id,
          question_id: context.question_id,
          option_id: context.option1.id,
          option_value: context.option_value,
          section_id: context.section_id,
          is_informational: context.is_informational
        };
      }else{
        var qryObj = {
          assessment_master_id: this.$route.params.id,
          question_id: context.question_id,
          option_id: context.selected_option.id,
          section_id: context.section_id,
          is_informational: context.is_informational
        };
      }
      this.$store.dispatch(STORE_ANSWER, qryObj);
      context.option_value = ""; 
    },
    getNextQuestion() {
      let context = this;
      // context.current_progress += parseInt(context.progress_interval);
      var next_question = 0;

      if(context.is_informational == 1){
        next_question = context.option1.next_question_id;
      }else{
        next_question = context.selected_option.next_question_id;
      }  
      
      axios({
        method: "get",
        url: "assessment-questionnaire",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: {
          question_id: next_question,
          assessment_master_id: this.$route.params.id
        }
      }).then(
        function(result) {
          if (result.data && result.data.assessment_question) {

            if((context.is_NA == 1) && 
              (context.option1.next_question_id == 0 ||
              context.option2.next_question_id == 0 ||
              context.option3.next_question_id == 0)){
              context.view_submit = true;
            }else if((context.is_NA == 0) && 
              (context.option1.next_question_id == 0 ||
              context.option2.next_question_id == 0)){
              context.view_submit = true;
            }

            context.selected_option = "";

            context.question_id = result.data.assessment_question.id;
            context.question = result.data.assessment_question.question_text;
            context.order = parseInt(result.data.assessment_question.order) + 1;
            context.is_informational = result.data.assessment_question.is_informational;
            if (result.data.assessment_question.options) {
              if(result.data.assessment_question.is_informational == 0){
                context.is_NA = result.data.assessment_question.is_NA;
                context.option1 = result.data.assessment_question.options[0];
                context.option2 = result.data.assessment_question.options[1];
                if(result.data.assessment_question.is_NA == 1){
                  context.option3 = result.data.assessment_question.options[2];
                }
              }else{
                context.option1 = result.data.assessment_question.options[0];
                context.selected_option = result.data.assessment_question.options[0];
                if(context.selected_option.next_question_id == 0){
                  context.view_submit = true;
                }else{
                  context.view_submit = false;
                }
              }
            }
            context.section_id = result.data.assessment_question.section_id;
            context.number_of_questions =
              result.data.assessment_question.number_of_questions;
            if (result.data.assessment_name && context.assessment_name == "") {
              context.assessment_name = result.data.assessment_name;
            }
            if (context.progress_interval == 0) {
              context.progress_interval = (
                100 / context.number_of_questions
              ).toFixed(2);
            }
            context.current_progress = parseInt(
              context.progress_interval * context.order
            );
            
          } else {
            // Swal.fire(result.data.error, "Question fetch error", "error");
          }
        },
        function() {
          // Swal.fire("Error", "Assessment Question not found!", "error");
          if (context.selected_option.option_score) {
            // context.assessment_score -= parseInt(
            //   context.selected_option.option_score
            // );
          }
        }
      );
    },
    redirectToAssessmentList() {
      let context = this;
      context.$router.go(-1);
    }
  }
};
</script>
